import customFunctions from "./customFunctions";

const menu = [
  {
    title: "Dashboard",
    path: "/",
    icon: "/images/dashboard.svg",
    roleaccess: [2, 3, 4, 5, 6, 10],
  },
  {
    title: "Clients",
    path: "/client-master-list",
    icon: "/images/Clients.svg",
    submenu: [],
    roleaccess: [2, 10],
  },
  {
    title: "Services",
    path: "/services",
    icon: "/images/adminstaff.svg",
    submenu: [],
    roleaccess: [1],
  },
  {
    title: "Tasks",
    path: "/super-admin-task-list",
    icon: "/images/adminstaff.svg",
    submenu: [],
    roleaccess: [1],
  },
  
  
  {
    title: "Employees",
    path: "/employee-list",
    icon: "/images/adminstaff.svg",
    submenu: [],
    roleaccess: [2, 10],
  },
  {
    title: "Timesheets",
    path: "/timesheet",
    icon: "/images/timesheet.svg",
    submenu: [],
    roleaccess: [2, 3, 4, 5, 10],
  },
  {
    title: "Tasks",
    path: "/employee-report",
    icon: "/images/template.svg",
    submenu: [],
    roleaccess: [3, 4, 5],
  },
  {
    title: "Assign Tasks",
    path: "/assign-task-list",
    icon: "/images/template.svg",
    submenu: [
      // {
      //   title: "Tasks Approval",
      //   path: "/tasks-approval",
      //   roleaccess: [2, 10],
      // },
      // {
      //   title: "Due Date Tasks",
      //   path: "/recurring-tasks-list",
      //   roleaccess: [2, 10],
      // },
      // {
      //   title: "Assign Tasks",
      //   path: "/assign-task-list",
      //   roleaccess: [2, 10],
      // },
    ],
    roleaccess: [2, 10],
  },

  {
    title: "Tasks Approval",
    path: "/tasks-approval",
    icon: "/images/template.svg",
    submenu: [],
    roleaccess: [2, 10],
  },
  {
    title: "Recurring Tasks",
    path: "#",
    icon: "/images/report.svg",
    submenu: [
      {
        title: "Recurring Tasks List",
        path: "/recurring-tasks-list",
        roleaccess: [2, 10],
      },
      {
        title: "Tasks Report",
        path: "/recurring-tasks-report",
        roleaccess: [2, 10],
      },
    ],
    roleaccess: [2, 10],
  },
  // {
  //   title: "Litigations",
  //   path: "/litigation-list",
  //   icon: "/images/line_item.svg",
  //   submenu: [
  //     // {
  //     //   title: "Litigations List",
  //     //   path: "/litigation-list",
  //     //   roleaccess: [2, 10],
  //     // },
  //     // {
  //     //   title: "Assign Litigations List",
  //     //   path: "/assign-litigations-list",
  //     //   roleaccess: [2, 10],
  //     // },
  //   ],
  //   roleaccess: [2, 10],
  // },
  {
    title: "Invoices",
    path: "#",
    icon: "/images/report.svg",
    submenu: [
    {
      title: "Invoices List",
      path: "/invoice-list",
      roleaccess: [2],
    },
    {
      title: "Terms",
      path: "/invoice-terms",
      roleaccess: [2],
    },
    ],
    roleaccess: [
      2,
      customFunctions.getLocalStorage("admin_access") == "true"
        ? Number(customFunctions.getLocalStorage("role_id"))
        : null,
    ],
  },

  {
    title: "Invoices",
    path: "invoice-list",
    icon: "/images/line_item.svg",
    roleaccess: [6],
  },
  
  {
    title: "Log Out",
    path: "#",
    icon: "/images/logout.svg",
    submenu: [],
    roleaccess: [1, 2, 3, 4, 5, 6, 7, 10],
  },
];

export default menu;
