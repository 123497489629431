import React, { useState, useEffect } from "react";
import { Helmet, TextInput, DropdownInput } from "../../components/FormInputs";
import { Card, Form, Col, Row, Accordion, Button } from "react-bootstrap";
import Table from "../../components/Table/simpletable.component";
import customFunctions from "../../helper/customFunctions";
import RejectReasonModel from "./rejectReasonModel";
import { useLocation, useNavigate } from "react-router-dom";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import swal from "sweetalert";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";
import SelectAsyncPaginate from "../../components/ReactSelectPagination/SelectAsyncPaginate";

function RejectTaskList(props: any) {
  const navigate = useNavigate();
  const [updateShow, setUpdateShow] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>(false);
  const [rejectedRows, setRejectedRows] = useState<any>([]);
  const [assignedRows, setAssignedRows] = useState<any>([]);
  const [title, setTitle] = useState<any>("Task List");
  const [roleId, setRoleId] = useState<number>(0);
  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [currentEmpId, setCurrentUserId] = useState<number>(0);
  const [count, forceUpdate] = useState<any>(0);
  const [rejectedTask, setRejectedTask] = useState<any>([]);
  const [assignedTask, setAssignedTask] = useState<any>([]);
  const [filterErr, setFilterErr] = useState<any>("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [selectedClient, setSelectedClient] = useState<any>("");
  const [clientDataArray, setClientDataArray] = useState<any>([]);
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [isShowFilter, setisShowFilter] = useState<boolean>(true);
  const state = useLocation();
  const comp = props?.fromComponent;
  const pageType = props?.pagetype;
  const initialRejectPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [rejectPagination, setRejectPagination] = useState(initialRejectPagination);

  const initialAssignedPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [assignedPagination, setAssignedPagination] = useState(initialAssignedPagination);
  const rejectedColumns = [
    {
      label: "Sr.No",
      id: "index",
    },
    {
      label: "Client Name",
      id: "client_name",
    },
    {
      label: "Task",
      id: "task_name",
    },
    {
      label: "Date",
      id: "date",
    },
    {
      label: "Reason",
      id: "note",
    },
  ];

  const assignedColumns = [
    {
      label: "Sr.No",
      id: "index",
    },
    {
      label: "Client Name",
      id: "client_name",
    },
    {
      label: "Task",
      id: "task_name",
    },
    {
      label: "Due Date",
      id: "date",
    }
  ];

  useEffect(() => {
    getClientList();
  }, []);

  const getClientList = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.clientUrl}?limit=1000`
    );
    if (data && data.success === true) {
      setShowLoader(false);
      let clientData: any = [];
      data &&
        data.data &&
        data.data.length > 0 &&
        data.data.map((item: any) => {
          if (item.client_status == 2) return;
          clientData.push({ label: item?.client_name, value: item.id });
        });
      setClientDataArray(clientData);
    } else {
      setShowLoader(false);
      swal(typeof message === "string" ? message : message[0], "", "error");
    }
  };

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  useEffect(() => {
    getRejectedTask(1, true);
  }, [props]);
  
  //get rejected tasks
  const getRejectedTask = async (pageNo?: any, clearFilter?: boolean) => {
    const page = pageNo || 1;
    setShowLoader(true);
    // setApplyToggle(false);
    const endPoint = Api.taskApprovalUrl;
    const params = {
      page: page,
      sort_direction: "descending",
      limit: comp == "dashboard" ? 10 : rejectPagination.itemsPerPage,
      approved_status: 2,
      client_id: !clearFilter && selectedClient?.id ? selectedClient?.id : "",
    };
    const url = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success == true) {
      setShowLoader(false);
      if (data && data?.data && !data?.data?.length && clearFilter) {
        setisShowFilter(false);
      }

      if (data && data.data && data.data.length > 0) {
        setShowLoader(false);
        setRejectPagination({
          currentPage: data.paginator.current_page,
          itemsPerPage: Number(10),
          totalItems: data.paginator.total_count,
        });
        setRejectedTask(data.data);
        forceUpdate(count + 1);
      } else {
        setRejectedTask([]);
      }
    } else {
      setShowLoader(false);
      setRejectedTask([]);
      swal(message, "", "error");
    }
  };

  useEffect(() => {
    getAssignedTask(1, true);
  }, [props]);

  //get assigned task
  const getAssignedTask = async (pageNo?: any, clearFilter?: boolean) => {
    const page = pageNo || 1;
    setShowLoader(true);
    // setApplyToggle(false);
    const endPoint = Api.employeeLitigationUrl;
    const params = {
      page: page,
      sort_direction: "descending",
      limit: comp == "dashboard" ? 10 : assignedPagination.itemsPerPage,
      // approved_status: 2,
      // client_id: !clearFilter && selectedClient?.id ? selectedClient?.id : "",
      task_type: 1,
    };
    const url = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success == true) {
      setShowLoader(false);
      if (data && data?.data && !data?.data?.length && clearFilter) {
        setisShowFilter(false);
      }

      if (data && data.data && data.data.length > 0) {
        setShowLoader(false);
        setAssignedPagination({
          currentPage: data.paginator.current_page,
          itemsPerPage: Number(10),
          totalItems: data.paginator.total_count,
        });
        setAssignedTask(data.data);
        forceUpdate(count + 1);
      } else {
        setAssignedTask([]);
      }
    } else {
      setShowLoader(false);
      setAssignedTask([]);
      swal(message, "", "error");
    }
  };


  //set rejected task data in table
  useEffect(() => {
    setRejectedTableData();
  }, [rejectedTask]);

  const setRejectedTableData = () => {
    const rowsRejectedData: any = [];
    rejectedTask.map((dt: any, index: any) => {
      // if(dt.approved_status == 3) return
      rowsRejectedData.push({
        index:
          (rejectPagination.currentPage - 1) * rejectPagination.itemsPerPage + (index + 1),
        client_name: dt.client_name,
        task_name: dt?.task?.task_name
          ? dt?.task?.task_name
          : dt?.litigation?.title,
        date: customFunctions.getDMYDate(dt?.updated_at, "dmy"),
        note: (
          <>
            <a
              href=""
              className="underline"
              title="View Note"
              onClick={(e: any) => {
                e.preventDefault();
                setModalData(dt);
                setUpdateShow(true);
              }}
            >
              View Note
            </a>
          </>
        ),
      });
    });
    setRejectedRows([...rowsRejectedData]);
  };

  //set assigned task data in table
  useEffect(() => {
    setAssignedTableData();
  }, [assignedTask]);

  const setAssignedTableData = () => {
    const rowsAssignedData: any = [];
    assignedTask.map((dt: any, index: any) => {
      // if(dt.approved_status == 3) return
      rowsAssignedData.push({
        index:
          (assignedPagination.currentPage - 1) * assignedPagination.itemsPerPage + (index + 1),
        client_name: dt?.client?.client_name,
        task_name: dt?.task?.task_name
          ? dt?.task?.task_name
          : dt?.litigation?.title,
        date: customFunctions.getDMYDate(dt?.task_due_date, "dmy"),
      });
    });
    setAssignedRows([...rowsAssignedData]);
  };


  const filterSearch = (e: any) => {
    e.preventDefault();
    let filterSearchErr = "";
    let isValid = true;
    if (customFunctions.checkIfEmpty(selectedClient)) {
      filterSearchErr = "Please select client";
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      getRejectedTask(1, false);
    }
  };

  const onClear = (e: any) => {
    setFilterErr("");
    setSearchKeyword("");
    setSelectedClient("");
    setApplyToggle(false);

    if (selectedClient) {
      getRejectedTask(1, true);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <Loader showLoader={isShowLoader} needFullPage={false} />
      {comp == "dashboard" ? (
        <section className="content-area">
           <Card>
            <Card.Header className="card-title text-dark">
              <center>Assigned Task List</center>
            </Card.Header>
            <Card.Body>
              <Table
                columns={assignedColumns}
                showCheckbox={false}
                rows={assignedRows}
                role={roleId}
                showPagination={true}
                paginationProps={{
                  ...assignedPagination,
                  currentPage: Number(assignedPagination.currentPage),
                  onPageChange: (pageNo: any) => {
                    getAssignedTask(pageNo, true);
                  },
                }}
                dropdownContainerClass="mb-3"
                emptyRender={() => (
                  <p className="text-center mb-0">No record found</p>
                )}
              />
            </Card.Body>
            {assignedRows?.length > 10 ? (
              <Card.Footer>
                <div>
                  <a
                    className="d-flex align-items-center justify-content-end underline"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();

                      navigate("/employee-report", {
                        state: {
                          // data: rows,
                          fromComponent: "dashboard",
                        },
                      });
                    }}
                  >
                    {"View all"}
                  </a>
                </div>
              </Card.Footer>
            ) : (
              ""
            )}
          </Card>
          <Card>
            <Card.Header className="card-title mb-0 text-dark">
              <center>Rejected Task List</center>
            </Card.Header>
            <Card.Body>
              <Table
                columns={rejectedColumns}
                showCheckbox={false}
                rows={rejectedRows}
                role={roleId}
                dropdownContainerClass="mb-3"
                emptyRender={() => (
                  <p className="text-center mb-0">No record found</p>
                )}
              />
            </Card.Body>
            {rejectedRows?.length > 10 ? (
              <Card.Footer>
                <div>
                  <a
                    className="d-flex align-items-center justify-content-end underline"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();

                      navigate("/employee-report", {
                        state: {
                          // data: rows,
                          fromComponent: "dashboard",
                        },
                      });
                    }}
                  >
                    {"View all"}
                  </a>
                </div>
              </Card.Footer>
            ) : (
              ""
            )}
          </Card>
        </section>
      ) : (
        <>
          <section className="content-area">
            {isShowFilter && (
              <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <h5 className="card-title mb-0">Filters</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Form onSubmit={(e) => filterSearch(e)}>
                      {filterErr && (
                        <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                          <div className="img-text">
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={(e) => onClear(e)}
                              src="/images/fail.svg"
                              alt="Success"
                            />{" "}
                            {filterErr}
                          </div>
                        </div>
                      )}
                      <Row className="mt-3">
                        <Col sm={3}>
                          <SelectAsyncPaginate
                            id={"Client"}
                            selectPlaceholder={"Select client"}
                            apiname={"clientUrl"}
                            endpoint={Api.clientUrl}
                            value={selectedClient}
                            onSelectChange={(select: any) => {
                              setSelectedClient(select);
                            }}
                          />
                        </Col>

                        <Col sm={3}>
                          <div className="filter-btn pull-right filter-btn-report">
                            <Button
                              variant="primary"
                              type="submit"
                              className="ms-4"
                              onClick={() => setApplyToggle(true)}
                            >
                              Apply
                            </Button>
                            <Button
                              variant="secondary"
                              type="button"
                              className="ms-4"
                              onClick={(e) => onClear(e)}
                            >
                              Clear
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
          </section>
          <Card className="mt-3">
            <Card.Body>
              <Row>
                <Col>
                  {
                    <Table
                      columns={rejectedColumns}
                      showCheckbox={false}
                      rows={rejectedRows}
                      showPagination
                      role={roleId}
                      paginationProps={{
                        ...rejectPagination,
                        currentPage: Number(rejectPagination.currentPage),
                        onPageChange: (pageNo: any) => {
                          getRejectedTask(pageNo, true);
                        },
                      }}
                      dropdownContainerClass="mb-3"
                      emptyRender={() => (
                        <p className="text-center mb-0">No record found</p>
                      )}
                    />
                  }
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </>
      )}

      {
        <RejectReasonModel
          show={updateShow}
          data={modalData}
          handleClose={() => {
            setUpdateShow(false);
          }}
        />
      }
    </>
  );
}

export default RejectTaskList;
